import '../css/contact.css';
import React from 'react';
import { Grid } from '@mui/material';

export function Contact() {

    return (
        <div style={{minHeight: '85vh'}}> 
            <div className='title-container'>
                <div className="title-name"><h1>Contact Us!</h1></div>
            </div>
            <div className='grid-container'>
                
                <Grid container spacing={4}>
                    <Grid item xs={6}>
                        <a href="mailto:judge@scrobotics.com">
                            <button className='contact-card'>
                                <h1>SC Judge</h1> 
                                <p className="email-font">judge@scrobotics.com</p>
                            </button>
                        </a>
                    </Grid>
                    <Grid item xs={6}>
                        <a href="mailto:ref@scrobotics.com">
                            <button className='contact-card'>
                                <h1>SC Referee</h1> 
                                <p className="email-font">ref@scrobotics.com</p>
                            </button>
                        </a>
                    </Grid>
                    <Grid item xs={6}>
                        <a href="mailto:coach.mentor@scrobotics.com">
                            <button className='contact-card'>
                                <h1>Coach Mentor</h1> 
                                <p className="email-font">coach.mentor@scrobotics.com</p>
                            </button>
                        </a>
                    </Grid>
                    <Grid item xs={6}>
                        <a href="mailto:fbongiorno@firstinspires.org">
                            <button className='contact-card'>
                                <h1>First Education</h1> 
                                <p className="email-font">fbongiorno@firstinspires.org</p>
                            </button>
                        </a>
                    </Grid>
                    <Grid item xs={6}>
                        <a href="mailto:pam.melling@firstsouthcarolina.org">
                            <button className='contact-card'>
                                <h1>FSM</h1> 
                                <p className="email-font">pam.melling@firstsouthcarolina.org</p>
                            </button>
                        </a>
                    </Grid>
                    <Grid item xs={6}>
                        <a href="mailto:catherine.zende@firstsouthcarolina.org">
                            <button className='contact-card'>
                                <h1>PDP</h1> 
                                <p className="email-font">catherine.zende@firstsouthcarolina.org</p>
                            </button>
                        </a>
                    </Grid>
                    <Grid item xs={3}><div></div></Grid>
                    
                    <Grid item xs={6} align="center" justify="center">
                        <a href="mailto:tonya.d.hamann.civ@us.navy.mil">
                            <button className='contact-card'>
                                <h1>NIWC</h1> 
                                <p className="email-font">tonya.d.hamann.civ@us.navy.mil</p>
                            </button>
                        </a>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
}
