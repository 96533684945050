import '../css/resources.css';
import React from 'react';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

export function Beginner() {
    return (
        <>
            <div className="row-general">
                <h1 className="page-title">Beginner Resources</h1>

                <div className="button-row">
                    <a href="https://ev3lessons.com/en/Lessons.html?tab=beginner" target="_blank" rel="noreferrer">
                        <button className="row-button-link" >Beginner EV3 Lessons</button>
                    </a>
                    <a href="https://primelessons.org/en/Lessons.html" target="_blank" rel="noreferrer" >
                        <button className="row-button-link" >Prime Lessons</button>
                    </a>
                </div>
            </div>
            <div className="column-container mb-4">
                <Row>
                    <Col className="column" lg={4} md={6} sm={12}>
                        <div className="section-title">
                            <h2>Programmers</h2>
                        </div>
                        <a href="https://primelessons.org/en/ProgrammingLessons/ConfiguringRobotMovement.pdf" target="_blank" rel="noreferrer">
                            <button className="button-link" >Configure Robot Movement</button>
                        </a>
                        <a href="https://ev3lessons.com/en/Lessons.html?tab=beginner" target="_blank" rel="noreferrer">
                            <button className="button-link" >Robot Moving Straight</button>
                        </a>
                    </Col>

                    <Col className="column" lg={4} md={6} sm={12}>
                        <div className="section-title">
                            <h2>Builders</h2>
                        </div>
                        <a href="https://ev3lessons.com/RobotDesigns/instructions/droidbot2instructions.pdf" target="_blank" rel="noreferrer">
                            <button className="button-link" >Droid Bot Instructions</button>
                        </a>
                        <a href="https://primelessons.org/RobotDesigns/instructions/DroidBotMSpikePrime.pdf" target="_blank" rel="noreferrer">
                            <button className="button-link" >Droid Bot M</button>
                        </a>
                    </Col>

                    <Col className="column" lg={4} md={12} sm={12}>
                        <div className="section-title">
                            <h2>Innovators</h2>
                        </div>
                        <a href="https://flltutorials.com/translations/en-us/Project/Identify.pdf" target="_blank" rel="noreferrer">
                            <button className="button-link" >Identifying a Problem</button>
                        </a>
                        <a href="https://flltutorials.com/translations/en-us/Project/BackgroundResearch.pdf" target="_blank" rel="noreferrer">
                            <button className="button-link" >Background Research</button>
                        </a>
                        <a href="https://flltutorials.com/translations/en-us/Project/Sharing.pdf" target="_blank" rel="noreferrer">
                            <button className="button-link" >Sharing Your Project</button>
                        </a>
                    </Col>
                </Row>
            </div>
        </>
    );
}
