import React from 'react';
import tipsLogo from '../../assets/images/RoboticTips.png';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';


const NavBar = () => {

    return (
        <Navbar expand="lg" className="navbar" >
            <Container fluid>
                <Nav>
                    <Navbar.Brand>
                        <img
                            alt=""
                            src={tipsLogo}
                            width="72"
                            height="72"
                            className="d-inline-block align-top nav-img"
                        />
                    </Navbar.Brand>
                </Nav>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav" style={{justifyContent:'flex-end'}}>
                    <Nav className="ml-auto d-flex">
                        <Nav.Link className="nav-link" href="/">Home</Nav.Link>
                        <Nav.Link className="nav-link" href="/beginner">Beginner</Nav.Link>
                        <Nav.Link className="nav-link" href="/intermediate">Intermediate</Nav.Link>
                        <Nav.Link className="nav-link" href="/expert">Expert</Nav.Link>
                        <Nav.Link className="nav-link" href="/events">Events</Nav.Link>
                        <Nav.Link className="nav-link" href="/contact">Contact Us</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}
export default NavBar;

//NavBar.js