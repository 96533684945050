import '../css/home.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import React, {useEffect} from 'react';

export function Home() {
    useEffect(() => {
        AOS.init({duration: 1500, once: true})
    },[])
    return (
        <>
            <div className="landing-banner">
                <div className="landing-greeting" data-aos="fade-up">
                    <div className="sub-text">Our Kids... Our Community... Our Future...</div>
                    <div className="header-text">Leading the DoD in Robotics</div>
                    <a href="#grants" className="btn btn-outline-danger landing-btn shrink-btn"> Learn More!</a>
                </div>
            </div>
            <div className="landing-level">
                <div className="row justify-content-center">
                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="level-item" data-aos="fade-up">
                            <div className="landing-title">Beginner</div>
                            <div className="landing-content">
                                Teams that are just beginning FIRST with less than 1 year of experience. Beginner teams have been to less than two seasons of qualifiers/tournaments.
                            </div>
                            <a href="/beginner" className="btn btn-outline-danger"> Learn More!</a>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="level-item" data-aos="fade-up" data-aos-delay="200">
                            <div className="landing-title">Intermediate</div>
                            <div className="landing-content">
                                Teams that have been to a couple qualifiers/tournaments but are still learning. Teams with &gt;2 years experience but &lt;5.
                            </div>
                            <a href="/intermediate" className="btn btn-outline-danger"> Learn More!</a>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="level-item" data-aos="fade-up" data-aos-delay="400">
                            <div className="landing-title">Expert</div>
                            <div className="landing-content">
                                Teams that have been around for more than 5 years.
                            </div>
                            <a href="/expert" className="btn btn-outline-danger"> Learn More!</a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="landing-about">
                <div className="landing-title" data-aos="fade-up">About Us</div>
                <div className="landing-content about" data-aos="fade-up">
                    NIWC’s goal with the STEMRocks Robotics web portal is to spread awareness of FIRST Lego League (FLL), FLL’s resources, and start and mentor NIWC-sponsored FLL teams. Within this web portal, you will find resources to better your knowledge of FLL competitions and robots, as well as links to FIRST’s website, contacts with whom to get involved, and how to get involved on a NIWC team. To the left are some suggested resources to get started with!
                </div>
                <div className="landing-content about" data-aos="fade-up">
                    Friendly competition is at the heart of Challenge, as teams of students ages 9-14 engage in research, problem-solving, coding and engineering – building and programming a LEGO robot that navigates the missions of a robot game. As part of Challenge, teams also participate in a research project to identify and solve a relevant real-world problem.
                </div>
            </div>
            <div className="landing-grants" id="grants">
                <div className="landing-title" data-aos="fade-up">DoD Grants</div>
                <div className="landing-content about" data-aos="fade-up">
                    NIWC Atlantic is a grant coordinator for DoD STEM grants. Each team requesting a DoD STEM grant must have a DoD Mentor. NIWC pairs requesting schools/organizations with a DoD Mentor that will help them navigate the season.
                </div>
                <div className="landing-content about" data-aos="fade-up">
                    Grants open in Mid-May and are announced by the end of August. Teams that receive a grant will have funds uploaded to their FIRST dashboard.
                </div>
                <div className="landing-content about" data-aos="fade-up">
                    If you are interested in applying for a grant to fund your FIRST robotics team (any FIRST level), please contact us at <a href="mailto:navalSTEMEvent@stemrocks.org">navalSTEMEvent@stemrocks.org</a> 
                </div>
            </div>
            <div className="landing-sponsors">
                <div className="landing-title">Our Sponsors</div>
                <div className="row sponsor-container">
                    <div className="sponsor-item col-xl-2 col-lg-4 col-md-6">
                        <img className="wide" data-aos="fade-up" src="https://stem-robotics.s3.us-east-2.amazonaws.com/images/cgi.png" alt="CGI Logo"></img>
                    </div>
                    <div className="sponsor-item col-xl-2 col-lg-4 col-md-6">
                        <img className="tall" data-aos="fade-up" src="https://stem-robotics.s3.us-east-2.amazonaws.com/images/dodstem.png" alt="DoDSTEM Logo"></img>
                    </div>
                    <div className="sponsor-item col-xl-2 col-lg-4 col-md-6">
                        <img className="wide" data-aos="fade-up" src="https://stem-robotics.s3.us-east-2.amazonaws.com/images/fll-challenge.jpg" alt="FLL Challenge"></img>
                    </div>
                    <div className="sponsor-item col-xl-2 col-lg-4 col-md-6">
                        <img className="wide" data-aos="fade-up" src="https://stem-robotics.s3.us-east-2.amazonaws.com/images/fll-sc.jpg" alt="FLL Challenge"></img>
                    </div>
                    <div className="sponsor-item col-xl-2 col-lg-4 col-md-6">
                        <img className="wide" data-aos="fade-up" src="https://stem-robotics.s3.us-east-2.amazonaws.com/images/niwc-lant.png" alt="FLL Challenge"></img>
                    </div>
                    <div className="sponsor-item col-xl-2 col-lg-4 col-md-6">
                        <img className="wide" data-aos="fade-up" src="https://stem-robotics.s3.us-east-2.amazonaws.com/images/stemrocks.png" alt="FLL Challenge"></img>
                    </div>
                </div>
            </div>
        </>
    )
}
