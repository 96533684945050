import { Route, Routes } from "react-router-dom";
import NavBar from './NavBar.js';
import { Home } from './Home';
import { Beginner } from "./Beginner.js";
import { Intermediate } from "./Intermediate.js";
import { Expert } from "./Expert.js";
import { Events } from "./Events.js";
import { Contact } from "./Contact.js";
import { Footer } from "./Footer.js";
import React from "react";

function App() {
    return (
        <>
            <NavBar />
            <Routes>
                <Route path='/' element={<Home />} />
                <Route path='/beginner' element={<Beginner />} />
                <Route path='/intermediate' element={<Intermediate />} />
                <Route path='/expert' element={<Expert />} />
                <Route path='/events' element={<Events />} />
                <Route path='/contact' element={<Contact />} />
            </Routes>
            <Footer />
        </>
    );
}

export default App;
