import '../css/events.css'
import React from 'react'
import Table from 'react-bootstrap/Table'

export function Events() {
    return (
        <>
            <h1 className="page-title">Robotics Events</h1>
            <div className="events-table">
                <Table variant="danger" hover bordered responsive>
                    <thead>
                        <tr>
                            <th>Event</th>
                            <th>Location</th>
                            <th>Date</th>
                            <th>Time</th>
                        </tr>
                    </thead>
                    <tbody className="table-group-divider">
                        <tr>
                            <td>FLL Challenge Kickoff</td>
                            <td>South Carolina Aquarium</td>
                            <td>September 22</td>
                            <td>6:00PM - 9:00PM</td>
                        </tr>
                        <tr>
                            <td>FLL Challenge Scrimmage - Erskine College</td>
                            <td>Erskine College, Due West, SC</td>
                            <td>October 8</td>
                            <td>8:30AM - 4:00PM</td>
                        </tr>
                        <tr>
                            <td >FLL Challenge Scrimmage - Sterling</td>
                            <td>Sterling School, Greenville, SC</td>
                            <td>October 22</td>
                            <td>8:30AM - 4:00PM</td>
                        </tr>
                        <tr>
                            <td>FLL Challenge Scrimmage - USC</td>
                            <td>Russell House, Columbia, SC</td>
                            <td>November 5</td>
                            <td>8:30AM - 4:00PM</td>
                        </tr>
                        <tr>
                            <td>FLL Challenge Scrimmage - Hanahan</td>
                            <td>TBD, North Charleston, SC</td>
                            <td>November 12</td>
                            <td>8:30AM - 4:00PM</td>
                        </tr>
                        <tr>
                            <td>FLL Challenge Qualifier - Tri-County</td>
                            <td>Tri-County Technical College, Pendleton, SC</td>
                            <td>December 3</td>
                            <td>8:00AM - 4:00PM</td>
                        </tr>
                        <tr>
                            <td>FLL Challenge Qualifier - Columbia</td>
                            <td>Dreher High School, Columbia, SC</td>
                            <td>December 3</td>
                            <td>8:00AM - 4:00PM</td>
                        </tr>
                        <tr>
                            <td >FLL Challenge Qualifier - Mauldin</td>
                            <td>Mauldin High School, Mauldin, SC</td>
                            <td>December 10</td>
                            <td>8:00AM - 4:00PM</td>
                        </tr>
                        <tr>
                            <td >FLL Challenge Qualifier - Charleston</td>
                            <td>Palmetto Scholars Academy, North Charleston, SC</td>
                            <td>December 10</td>
                            <td>8:00AM - 4:00PM</td>
                        </tr>
                        <tr>
                            <td >FLL Challenge Qualifier - Colleton</td>
                            <td>Colleton, SC</td>
                            <td>January 14</td>
                            <td>8:00AM - 4:00PM</td>
                        </tr>
                        <tr>
                            <td >FLL Challenge Qualifier - Abbeville</td>
                            <td>Wright Middle School, Abbeville, SC.</td>
                            <td>January 21</td>
                            <td>8:00AM - 4:00PM</td>
                        </tr>
                        <tr>
                            <td>FLL Challenge Qualifier - East Edisto</td>
                            <td>East Edisto Middle School, Summerville, SC</td>
                            <td>January 21</td>
                            <td>TBA</td>
                        </tr>
                        <tr>
                            <td >FLL Challenge State Championship</td>
                            <td>Meadow Glen Middle School, Lexington, SC</td>
                            <td>February 11</td>
                            <td>8:00AM - 4:00PM</td>
                        </tr>
                    </tbody>
                </Table>
            </div>
        </>
    );
}
