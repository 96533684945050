import '../css/footer.css';
import React from 'react';

export function Footer() {
    return  (
        <footer className="footer">
            <div className="footer-container">
                <p>© NAVAL INFORMATION WARFARE CENTER:
                <a href="https://www.niwcatlantic.navy.mil/" className="footer-link"> NIWC Atlantic</a></p>
                <a className="footer-link" href="https://robotics-tips.web.app/images/RoboticsPrivacyPolicy.pdf">Privacy Policy</a>
            </div>
        </footer>
    )
}