import '../css/resources.css';
import React from 'react';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

export function Expert() {
    return (
        <>
            <div className="row-general">
                <h1 className="page-title">Expert Resources</h1>
                <div className="button-row">
                    <a href="https://ev3lessons.com/en/Lessons.html?tab=advanced" target="_blank" rel="noreferrer">
                        <button className="row-button-link" >Expert EV3 Lessons</button>
                    </a>
                    <a href="https://primelessons.org/en/Lessons.html" target="_blank" rel="noreferrer" >
                        <button className="row-button-link" >Prime Lessons</button>
                    </a>
                </div>
            </div>
            <div className="column-container mb-4">
                <Row>
                    <Col className="column" lg={4} md={6} sm={12}>
                        <div className="section-title">
                            <h2>Programmers</h2>
                        </div>
                        <a href="https://primelessons.org/en/PyLessons.html" target="_blank" rel="noreferrer">
                            <button className="button-link" >Py Lessons</button>
                        </a>
                    </Col>

                    <Col className="column" lg={4} md={6} sm={12}>
                        <div className="section-title">
                            <h2>Builders</h2>
                        </div>
                        <a href="https://www.fllcasts.com/materials/" target="_blank" rel="noreferrer">
                            <button className="button-link" >FLL Materials</button>
                        </a>
                    </Col>

                    <Col className="column" lg={4} md={12} sm={12}>
                        <div className="section-title">
                            <h2>Innovators</h2>
                        </div>
                        <a href="https://flltutorials.com/translations/en-us/Project/ProjectNextLevel.pdf" target="_blank" rel="noreferrer">
                            <button className="button-link" >Project Next Level</button>
                        </a>
                        <a href="https://flltutorials.com/translations/en-us/Project/GlobalInnovationAward.pdf" target="_blank" rel="noreferrer">
                            <button className="button-link" >Global Innovation Award</button>
                        </a>

                    </Col>
                </Row>
            </div>
        </>
    );
}