import '../css/resources.css';
import React from 'react';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

export function Intermediate() {
    return (
        <>
            <div className="row-general">
                <h1 className="page-title">Intermediate Resources</h1>
                <div className="button-row">
                    <a href="https://ev3lessons.com/en/Lessons.html?tab=intermediate" target="_blank" rel="noreferrer">
                        <button className="row-button-link" >Intermediate EV3 Lessons</button>
                    </a>
                    <a href="https://primelessons.org/en/Lessons.html" target="_blank" rel="noreferrer" >
                        <button className="row-button-link" >Prime Lessons</button>
                    </a>
                </div>
            </div>
            <div className="column-container mb-4">
                <Row>
                    <Col className="column" lg={4} md={6} sm={12}>
                        <div className="section-title">
                            <h2>Programmers</h2>
                        </div>
                        <a href="https://primelessons.org/en/ProgrammingLessons/GyroTurning.pdf" target="_blank" rel="noreferrer">
                            <button className="button-link" >Gyro Turning</button>
                        </a>
                        <a href="hhttps://primelessons.org/en/ProgrammingLessons/AccurateTurning.pdf" target="_blank" rel="noreferrer">
                            <button className="button-link" >Accurate Turning</button>
                        </a>
                    </Col>
                    <Col className="column" lg={4} md={6} sm={12}>
                        <div className="section-title">
                            <h2>Builders</h2>
                        </div>
                        <a href="https://primelessons.org/RobotDesigns/instructions/DroidBotEBuildInstructions.pdf" target="_blank" rel="noreferrer">
                            <button className="button-link" >Droid Bot E Instructions</button>
                        </a>
                    </Col>
                    <Col className="column" lg={4} md={12} sm={12}>
                        <div className="section-title">
                            <h2>Innovators</h2>
                        </div>
                        <a href="https://flltutorials.com/translations/en-us/Project/InnovativeSolution.pdf" target="_blank" rel="noreferrer">
                            <button className="button-link" >Innovative Solutions</button>
                        </a>
                        <a href="https://flltutorials.com/translations/en-us/Project/Presentation.pdf" target="_blank" rel="noreferrer">
                            <button className="button-link" >Features of a Good Presentation</button>
                        </a>
                    </Col>
                </Row>

            </div>
        </>
    );
}